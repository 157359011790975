import React from "react"
import { Card, Row, Col, Container } from "react-bootstrap"
import Image from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const getImages = graphql`
  {
    fluid: file(relativePath: { eq: "LeadersForPeace.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 700) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
const PlayhouseHead = () => {
  const data = useStaticQuery(getImages)
  return (
    <Container>
      <Row>
        <Col
          xs={{ span: 12 }}
          sm={{ span: 12 }}
          md={{ span: 12 }}
          lg={{ span: 4 }}
          xl={{ span: 4 }}
          style={{ paddingLeft: "0px", maxHeight: "550px", height: "500px" }}
        >
          <Image
            fluid={data.fluid.childImageSharp.fluid}
            alt={"Leaders For Peace "}
            style={{ height: "100%", width: "100%" }}
            imgStyle={{
              objectFit: "contain",
            }}
          />
        </Col>
        <Col
          xs={{ span: 12 }}
          sm={{ span: 12 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
          xl={{ span: 8 }}
          style={{ paddingLeft: "0px" }}
        >
          <Card.Title>
            Storytelling Methodology Manual and Leaders for Peace Programme
            (2021-2022)
          </Card.Title>
          <Card.Text style={{ marginBottom: "12px" }} className="anti-copy">
            January 2021: Dr Liam Campbell and I began consultations concerning
            the project ‘A Storytelling Methodology’. This is the upskilling and
            training manual used by Theatre of Witness (TOW) and Theatre
            Peacebuilding Academy (TPA) for guiding participants in facilitating
            workshops that teach Leaders for Peace. Dr Liam Campbell and I
            devised the working title:{" "}
            <i>
              ‘Storytelling Training Methodologies’ post conflict, post trauma,
              cross community. Creating a unified and empowered cohort of
              community leaders and peacemakers who can effect positive and
              meaningful change.
            </i>
          </Card.Text>
        </Col>
        <Col
          xs={{ span: 12 }}
          sm={{ span: 12 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
          style={{ paddingLeft: "0px" }}
        >
          <Card.Text
            style={{ marginBottom: "12px", marginTop: "12px" }}
            className="anti-copy"
          >
            In reflecting on 30 years’ experience as a facilitator using
            resources that proved invaluable for facilitating storytelling
            workshops, I realised had through study and teaching amassed a
            wealth of resources. These resources enabled facilitating Outreach
            Programmes, working in Prisons, Community Groups, Health Forums,
            Cancer Awareness, Youth Groups, Literacy Programmes, Survivors of
            Domestic Abuse, the Travelling Community, Women’s’ Groups, Mental
            Health Agencies, Primary and Secondary Schools. I also designed the
            weekly resource for Prisoners in HMP Magilligan during the COVID-19
            pandemic with the suspension of face-to-face teaching.
          </Card.Text>
        </Col>
        <Col
          xs={{ span: 12 }}
          sm={{ span: 12 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
          style={{ paddingLeft: "0px" }}
        >
          <Card.Text
            style={{ marginBottom: "12px", marginTop: "12px" }}
            className="anti-copy"
          >
            I decided the manual needed chronology that mirrored how a workshop
            was conducted. The introduction and the early sections/chapters
            became the ‘why’ of storytelling, lesson planning, using the manual,
            the role of the facilitator, the workshop space, the storytelling
            tasks, permission slips and the glossary. There was a narrative
            journey and a step-by-step process for workshops to achieve the
            outcomes. These aspects were extensively supported by the
            bibliography as well as the rich research and footnotes.
          </Card.Text>
        </Col>
        <Col
          xs={{ span: 12 }}
          sm={{ span: 12 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
          style={{ paddingLeft: "0px" }}
        >
          <Card.Text
            style={{ marginBottom: "12px", marginTop: "12px" }}
            className="anti-copy"
          >
            I updated the ‘Mindfulness’ section—and devised an initial workshop
            where a counsellor could be present to conduct sessions with a
            facilitator. I updated the sections on symbolism to include the NI
            context and addressed the need for a ‘safe space’ and ‘reliable and
            well sourced resources’ along with support systems and ‘preparation
            time’, and notice for ‘training sessions’ were highlighted with
            ‘integrity’ and ‘education’ as the repeated themes.
          </Card.Text>
        </Col>
        <Col
          xs={{ span: 12 }}
          sm={{ span: 12 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
          style={{ paddingLeft: "0px" }}
        >
          <Card.Text
            style={{ marginBottom: "12px", marginTop: "12px" }}
            className="anti-copy"
          >
            There was the necessity of demonstrating{" "}
            <i>how your story has evolved</i>
            and repeating your model leading to being ‘boxed in and restricted.’
            Participants reflecting changes in the storyteller was a key point.
            I worked towards engagement with the exercises in the manual to
            enable participants to ‘grow’ their story. Focus groups included TOW
            and TPA, highlighted the need for the chapters on Ethics and Ethical
            storytelling, co-facilitation and shadow-facilitation, as well as
            the use of ‘story’ as opposed to ‘testimony’.
          </Card.Text>
        </Col>
        <Col
          xs={{ span: 12 }}
          sm={{ span: 12 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
          style={{ paddingLeft: "0px" }}
        >
          <Card.Text
            style={{ marginBottom: "12px", marginTop: "12px" }}
            className="anti-copy"
          >
            The ongoing meetings and up-dates with Liam Campbell further
            extended into the selecting quotes from TPA and TOW for inclusion in
            the manual. Permissions were granted to add Dr Liam Campbell’s
            article on “Active Listening” and Dr Kevin Kiely’s academic article
            “Modernist and Post-Modern Storytelling”. Dr Kiely further granted
            permission to use his translation of a poem by Rilke as resource
            content, and otherwise he provided editorial feedback on the final
            draft of the manual.
          </Card.Text>
        </Col>
        <Col
          xs={{ span: 12 }}
          sm={{ span: 12 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
          style={{ paddingLeft: "0px" }}
        >
          <Card.Text
            style={{ marginBottom: "12px", marginTop: "12px" }}
            className="anti-copy"
          >
            The creation of the manual required extensive work, incorporating
            the multiple layers of individual and group reflections while
            achieving a balance of the variegated contents. It is ‘user
            friendly’ and achieves the essential requirements of being
            professional, complex, practical and theoretical, a guide for the
            storyteller to share their narrative while growing in the process of
            sharing, as well as demonstrating that meta-narratives belong to the
            broader story of growth, healing and understanding.
          </Card.Text>
        </Col>
        <Col
          xs={{ span: 12 }}
          sm={{ span: 12 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
          style={{ paddingLeft: "0px" }}
        >
          <Card.Text
            style={{ marginBottom: "12px", marginTop: "12px" }}
            className="anti-copy"
          >
            As a concept, Dr Liam Campbell not only supported the ‘progressive
            template’ but also compelled it forwards through his belief and
            dedication to Peacebuilding. It is with profound sadness that his
            passing on the 20 December 2021 closed off the year of intense work.
            Dr Campbell, of course, leaves an unquestionable legacy, he leaves
            this ‘model’ for which we owe him a huge debt of gratitude.
          </Card.Text>
        </Col>
      </Row>
    </Container>
  )
}

export default PlayhouseHead
