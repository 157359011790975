import React from "react"
import Layout from "../components/layout"
import { Card } from "react-bootstrap"
import { Helmet } from "react-helmet"
import PlayhouseHead from "../components/PlayhouseHead"
const Playhouse = () => {
  return (
    <Layout>
      <Helmet>
        <title>The Playhouse - Pamela Mary Brown</title>
      </Helmet>
      <h2>The Playhouse</h2>
      <Card
        style={{
          border: "none",
          backgroundColor: "transparent",
          padding: "0px",
        }}
      >
        <PlayhouseHead />
        <hr style={{ background: "black" }} />
        <Card.Title style={{ marginTop: "10px" }}>
          Questions of Legacy
        </Card.Title>
        <div className="video-style">
          <iframe
            width="100%"
            height="350"
            src="https://www.youtube.com/embed/luW4DzQah2A?showinfo=0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <hr style={{ background: "black" }} />
        <Card.Title style={{ marginTop: "10px" }}>
          An Artists Perspective - Art in Action - Creative Dialogue{" "}
        </Card.Title>
        <Card.Text style={{ width: "100%" }}>
          <div className="video-style">
            <iframe
              width="100%"
              height="350"
              src="https://www.youtube.com/embed/S8cd7omG3Tk?showinfo=0"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </Card.Text>
      </Card>
    </Layout>
  )
}

export default Playhouse
